@import './node_modules/bootstrap-sass/assets/stylesheets/bootstrap-sprockets';

// http://www.colourlovers.com/palette/4322416/Tropicalia

$colour-aroseforemily: #D4361D; // A Rose for Emily
$colour-spices: #E28F31; // Spices
$colour-colourfulgreened: #70BD55; // Colourful Greened
$colour-picole: #389D3D; // Picolé
$colour-leftovers: #1F2C0E; // left overs

$brand-primary: $colour-colourfulgreened;
$brand-success: $colour-picole;
$brand-info: $colour-leftovers;
$brand-warning: $colour-spices;
$brand-danger: $colour-aroseforemily;

$jumbotron-hero-from-colour: $colour-colourfulgreened;

@import './node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables';

@import './src/styles/vendor/bootstrap-custom';

@import './src/styles/components/blog';
@import './src/styles/components/social-links';
@import './src/styles/components/photo-library';
//@import './src/styles/components/btn-breadcrumb';
@import './src/styles/components/sticky-footer';
//@import './src/styles/components/fullscreen-background';
@import './src/styles/components/alerts';
@import './src/styles/components/loader';
@import './src/styles/components/jumbotron';
